import $ from 'jquery'
import 'slick-carousel'
import './vendor/rwdImageMap.min'
import Cookies from 'js-cookie'

// Toggle navigation when navigation button is clicked
$('#nav-toggle').on('click', function () {
  $('#nav ul').toggleClass('expanded');
});

// Close nav when option is selected
$('.nav__link').on('click', function () {
  $('#nav ul').removeClass('expanded')
});

// Make external links open in new tab.
$("a[href^='http']").attr('target', '_blank');

// Detect scroll position for changing the navigation mode
$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  $('#nav').toggleClass('toolbar', scroll > 0);

  // Show flag at the same position where the 'next' arrow takes one.
  $('#nav').toggleClass('flagged', scroll > $('#main').offset().top - $('#nav').height() - 1);
});

// Smooth scroll when clicking on in-site links.
// https://stackoverflow.com/a/7717572/925580
$(document).on('click', 'area[href^="#"], a[href^="#"]', function (event) {
  event.preventDefault();
  $('html, body').animate({
    scrollTop: $($.attr(this, 'href')).offset().top - $('#nav').height()
  }, 300);
});

// Ensure slider buttons stay centered
$(window).on('resize', centerSliderButtons);
$('.slider article img').on('load', centerSliderButtons);
function centerSliderButtons() {
  var imgHeight = $('.slider article img').height();
  $('.slider__arrow img').each(function () {
    var button = $(this);
    var buttonHeight = button.height();
    var top = imgHeight / 2 - buttonHeight / 2;
    button.css('top', top + 'px');
  });
}

// Slider
$("#youtubeslider .slider__items")
  .on('init', function () {
      console.log('init slider');
    centerSliderButtons();
  })
  .slick({
    prevArrow: $('#youtubeslider .slider__arrow__prev'),
    nextArrow: $('#youtubeslider .slider__arrow__next'),
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    variableHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        variableWidth: false,
        centerPadding: '24px',
      }
    }]
  });
// Slider
$("#startupslider .slider__items")
  .on('init', function () {
      console.log('init slider');
    centerSliderButtons();
  })
  .slick({
    prevArrow: $('#startupslider .slider__arrow__prev'),
    nextArrow: $('#startupslider .slider__arrow__next'),
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    variableHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        variableWidth: false,
        centerPadding: '24px',
      }
    }]
  });


// Responsive image maps
$('img[usemap]').rwdImageMaps();

// Show / hide partner texts when map areas are clicked
function hidePartners() {
  $('.map__partner').addClass('hidden');
}
function showPartner(event) {
  event.preventDefault();
  event.stopPropagation();
  hidePartners();
  var href = $(this).attr('href');
  var partner = $(href);
  partner.removeClass('hidden');
}
$('.map__partner').on('mouseleave', hidePartners);
$('.map__partner').on('click', function (event) {
  event.stopPropagation();
});
hidePartners();
$(window).on('click', hidePartners);
$('.map area').on('mouseenter', showPartner);
$('.map area').on('click', showPartner);

// Ensure page fills whole window
function resizeSpacer() {
  setTimeout(() => {
    $('#spacer').height(0); // reset
    var spacerHeight = Math.max(0, $(window).height() - $('body').height() - 1);
    $('#spacer').height(spacerHeight);
  }, 1)
}
resizeSpacer();
$(window).on('resize', resizeSpacer);

$('.expandable').each(function () {
  var expandable = $(this);
  var onlyNarrow = expandable.data('narrow');
  var isNarrow = $(window).width() < 768;
  if (onlyNarrow && !isNarrow) {
    expandable.addClass('expandable__expanded');
    return;
  }
  var button = expandable.find('.expandable__more');
  var collapsedHeight = expandable.data('height');
  expandable.css('height', collapsedHeight + 'px');
  button.on('click', function () {
    expandable.css('height', 'auto');
    var expandedHeight = expandable.height();
    expandable.css('height', collapsedHeight + 'px');
    expandable.animate({height: expandedHeight + 'px'}, function () {
      expandable.css('height', 'auto');
    });
    expandable.addClass('expandable__expanded');
  });
});

// Set all numbers to 0 before animation.
$('.numbers__amount').each(function () {
  var element = $(this);
  element.attr('data-animate', true);
  var value = element.text().replace('.', '');
  element.attr('data-value', value);
  element.text(0);
});

// Trigger number animation as soon as it becomes visible.
function animateNumbers() {
  var scroll = $(window).scrollTop() + $(window).height();
  $('.numbers__amount[data-animate="true"]').each(function () {
    var element = $(this);
    if (scroll > element.offset().top) {
      element.attr('data-animate', false);
      var max = element.attr('data-value');
      var num = 0;
      var duration = 3000;
      var steps = Math.min(100, max);
      var step = Math.ceil(max / steps);
      var interval = setInterval(function () {
        var numString = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        element.text(numString);
        num = Math.min(max, num);
        if (num >= max) clearInterval(interval);
        num += step;
      }, Math.round(duration / steps));
    }
  });
}
animateNumbers();
$(window).scroll(animateNumbers);

// In Safari the page does not overflow but scrolls horizontally.
// overflow-x: hidden does not work: https://stackoverflow.com/q/6421966/925580
// Since Safari shows no horizontal scrollbar we just disable it.
// Source: https://stackoverflow.com/a/29431155/925580
var $body = $(document);
$body.bind('scroll', function() {
  // "Disable" the horizontal scroll.
  if ($body.scrollLeft() !== 0) {
    $body.scrollLeft(0);
  }
});

function googleTagManager () {
  ;(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WR2KTVX');
}

// Cookie consent cookie
if (Cookies.get('cookie-consent')) {
  googleTagManager()
} else {
  $('.cookies').removeAttr('hidden')
}
$('.cookies__consent').on('click', function () {
  Cookies.set('cookie-consent', true)
  googleTagManager()
  $('.cookies').fadeOut()
})
$('.cookies__dissent').on('click', function () {
  $('.cookies').fadeOut()
})


$('.speaker .openModal').on('click', function() {
    $(this).parent().find('.speaker-modal').fadeIn();
});

$('.trigger-speaker').on('click', function() {

    $('#speaker-' + $(this).attr('data-speakerid')).trigger('click');
})

$('.speaker-modal').on('click', function () {
    $(this).fadeOut();
})
